import React, {useState} from "react"
import tw, {styled} from 'twin.macro'
import { Link } from "gatsby"
import { useScrollPosition } from "./use_scroll_position"


const NavItem = styled(Link)`
  text-decoration: none;
  font-weight: 900;
  font-size: .75em;
  ${props => props.color};
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all .2s ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    content: ".";
    color: transparent;
    ${tw`bg-cygreen`};
    height: .35em;
    margin-bottom: 1px;
    transition: all 0.2s ease-in;
    z-index: -1;
  }

  :hover {
    color: #F7F2F3;
    text-decoration: none;
    ::after {
      width: ${props => props.hoverwidth};
    }
  }

  @media (max-width: 768px) {
    padding: 20px 1rem;
    font-size: 1.5rem;
    z-index: 6;
  }
`


const NavbarLinks = () => {

  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({prevPos, currPos}) => {
    const isShow = currPos.y > -650
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  },
    [hideOnScroll])

  return (
    <>
      <NavItem color={tw`text-cyblue`} hoverwidth={'100%'} hideOnScroll = {hideOnScroll} to="/">Home</NavItem>
      <NavItem color={tw`text-cyblue`} hoverwidth={'100%'} hideOnScroll = {hideOnScroll} to="/about">About</NavItem>
      <NavItem color={tw`text-cyblue`} hoverwidth={'100%'} hideOnScroll = {hideOnScroll} to="/courses">Courses</NavItem>
      <NavItem color={tw`text-cyblue`} hoverwidth={'100%'} hideOnScroll = {hideOnScroll} to="/FAQ">Faq</NavItem>
      <NavItem color={tw`text-cyblue`} hoverwidth={'100%'} hideOnScroll = {hideOnScroll} to="/blog">Blog</NavItem>
      <NavItem color={tw`text-white bg-cygreen px-2 rounded-md transition duration-300 transform hover:shadow-md hover:bg-cydarkgreen`} hoverwidth={'0'} hideOnScroll = {hideOnScroll} to="https://cultureyard.as.me/">Login</NavItem>
    </>
  )
}

export default NavbarLinks

