// downloaded
import React from "react"
import { Link } from "gatsby"
import tw from 'twin.macro'



// internal
import Navbar from "./navbar"
import Footer from "./footer"

const Wrapper = tw.div`
    relative
    px-5
    lg:mx-32
    xl:mx-40
    flex
    flex-col
    align-middle
    justify-center
    py-8
    md:py-24
    min-h-screen
`
const Layout = ({ children, logoImage }) => {

  return (
    <div>
      <Navbar logoImage={logoImage} />
      <Wrapper>
        <div>
          <main>{children}</main>
        </div>
      </Wrapper>
      <Footer logoImage={logoImage} />
    </div>
  )
}

export default Layout
