import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import tw, {styled} from 'twin.macro'
import {motion} from 'framer-motion'

import Logo from "./logo"
import Modal from './modal'

const Footer = ({ logoImage }) => {

  const [toggleOpen, setToggleOpen] = useState(false)


  const data = useStaticQuery(graphql`
  {
      contentfulContactInfo {
        address
        addressGoogleMaps
        facebook
        instagram
        phoneNumber
        wechatContact
        wechatOfficial
        whatsappNumber
        youtube
        whatsappLink
        phoneNumberLink
        email
        emaillink
        wechatOfficialAcctQr {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)

  const contact = data.contentfulContactInfo

  const FooterWrapper = tw.footer`
    bg-cylightblue
    bg-opacity-25
    flex
    flex-col
    mt-12
    pt-12
    px-4
    md:px-20
    md:flex-row
    justify-between
  `

  const ColumnWrapper = tw.div`
    w-full
    md:w-3/4
    flex
    justify-around
    md:justify-start
  `

  const FooterColumn = tw.div`
    md:w-2/5
    lg:w-1/4
    mx-0
  `

  const FooterList =tw.ul`
    mx-0
    mt-2
    text-xs
    list-none

  `

  const Title = tw.span`
    font-semibold
    mb-4
  `

  const LogoWrapper = tw.div`
    pb-5
    mx-0
    flex
    flex-col
    items-center

`

  const Navbox = styled.div`
    display: flex;
    height: 100vh;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    ${tw`bg-cygrey`};
    transition: all 0.3s ease-in;
    top: 6vh;
    left: ${props => (props.open ? "-100%" : "0")};
    }
  `

  const Path = props => (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke="hsl(0, 0%, 18%)"
      strokeLinecap="round"
      color="#4A6C6F"
      {...props}
    />
  );
  
  const CloseButton = () => (
    <button onClick={() => setToggleOpen(false)} tw='absolute top-0 right-0 px-4 py-8 lg:px-8 '>
      <svg width="35" height="35" viewBox="0 0 23 23">
        <Path d="M 3 16.5 L 17 2.5" />
        <Path d="M 3 2.5 L 17 16.346" />
      </svg>
    </button>
  );


return(

  <FooterWrapper id="footer">
     {toggleOpen ? <Navbox><CloseButton /><Modal wechatName={contact.wechatOfficial} wechatQR={contact.wechatOfficialAcctQr.fluid} /></Navbox> : <div></div> } 
    <ColumnWrapper>
      <FooterColumn id="company">
        <Title>Company</Title>
        <FooterList>
          <li tw="mb-1"><Link to="/about"><strong>About Us</strong></Link></li>
          <li tw="mb-1"><Link to="/privacy"><strong>Privacy</strong></Link></li>
          <li tw="mb-1"><Link to="/terms"><strong>Terms & Conditions</strong></Link></li>
        </FooterList>
      </FooterColumn>

      <FooterColumn id="contact">
        <Title>Contact</Title>
        <FooterList>
          <li tw="mb-1"><strong>Phone:</strong> <a href={contact.phoneNumberLink} target="blank">{contact.phoneNumber}</a></li>
          <li tw="mb-1"><strong>Email:</strong> <a href={contact.emaillink} target="blank">{contact.email}</a></li>
          <li tw="mb-1"><strong>WhatsApp:</strong> <a href={contact.whatsappLink} target="blank">{contact.whatsappNumber}</a></li>
          <li tw="mb-1"><strong>WeChat:</strong> {contact.wechatContact}</li>
          <li tw="mb-1"><strong>Address:</strong> <a href={contact.addressGoogleMaps} target="blank">{contact.address}</a></li>
        </FooterList>
      </FooterColumn>
    </ColumnWrapper>

    <LogoWrapper>
      <Link tw="no-underline" to="/">
        <Logo logoImage={logoImage} />
      </Link>

      <ul tw="flex flex-row justify-around py-2 m-0 list-none">
        <li tw="pr-3">
          <a href={contact.facebook} target="blank">
            <svg id="facebook" tw="h-6 w-6" fill="#4A6C6F" viewBox="0 0 512 512">
              <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"></path>
            </svg>
          </a>
        </li>
        <li tw="pr-3">
          <a href={contact.instagram} target="blank">
            <svg id="instagram" tw="h-6 w-6" fill="#4A6C6F" viewBox="0 0 448 512">
              <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
            </svg>
          </a>
        </li>
        <li tw="pr-3" toggleOpen={toggleOpen} onClick={() => setToggleOpen(true)} tw="cursor-pointer">
            <svg id="weixin" tw="h-6 w-6" fill="#4A6C6F" viewBox="0 0 576 512">
              <path d="M385.2 167.6c6.4 0 12.6.3 18.8 1.1C387.4 90.3 303.3 32 207.7 32 100.5 32 13 104.8 13 197.4c0 53.4 29.3 97.5 77.9 131.6l-19.3 58.6 68-34.1c24.4 4.8 43.8 9.7 68.2 9.7 6.2 0 12.1-.3 18.3-.8-4-12.9-6.2-26.6-6.2-40.8-.1-84.9 72.9-154 165.3-154zm-104.5-52.9c14.5 0 24.2 9.7 24.2 24.4 0 14.5-9.7 24.2-24.2 24.2-14.8 0-29.3-9.7-29.3-24.2.1-14.7 14.6-24.4 29.3-24.4zm-136.4 48.6c-14.5 0-29.3-9.7-29.3-24.2 0-14.8 14.8-24.4 29.3-24.4 14.8 0 24.4 9.7 24.4 24.4 0 14.6-9.6 24.2-24.4 24.2zM563 319.4c0-77.9-77.9-141.3-165.4-141.3-92.7 0-165.4 63.4-165.4 141.3S305 460.7 397.6 460.7c19.3 0 38.9-5.1 58.6-9.9l53.4 29.3-14.8-48.6C534 402.1 563 363.2 563 319.4zm-219.1-24.5c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.8 0 24.4 9.7 24.4 19.3 0 10-9.7 19.6-24.4 19.6zm107.1 0c-9.7 0-19.3-9.7-19.3-19.6 0-9.7 9.7-19.3 19.3-19.3 14.5 0 24.4 9.7 24.4 19.3.1 10-9.9 19.6-24.4 19.6z"></path>
            </svg>
        </li>
        <li tw="pr-3">
          <a href={contact.youtube} target="blank">
            <svg id="youtube" tw="h-6 w-6" fill="#4A6C6F" viewBox="0 0 576 512">
              <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
            </svg>
          </a>
        </li>
      </ul>
      <p tw="text-xs">© 2020 Culture Yard. All rights reserved</p>
    </LogoWrapper>

  </FooterWrapper>

  )
}

export default Footer
