import React from "react"
import tw from 'twin.macro'
import Img from 'gatsby-image'

const Container = tw.div`
px-12
py-12
md:px-16
md:py-16
`

const Modal = ({toggleOpen, wechatName, wechatQR}) => {

    return (
        <Container>
        <h4 tw="mb-3">Wechat official account name:</h4>
        <p>{wechatName}</p>
        <h4 tw="mb-3">QR Code:</h4>
        <Img fluid={wechatQR} alt="Qr code" />
        </Container>
    )
}

export default Modal;
