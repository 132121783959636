//downloaded
import React, { useState } from 'react'
import tw, {styled} from 'twin.macro'



//components
import NavbarLinks from "./navbar_links"
import Logo from "./logo"
import { useScrollPosition } from "./use_scroll_position"



const Navigation = styled.nav`
  ${tw`bg-cygrey`};
  height: 6vh;
  min-height: 45px;
  display: flex;
  position: relative;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0 5rem;
  z-index: 20;
  align-self: center;
  position: sticky;
  top: 0;
  min-width: 100%;
  box-shadow: ${props => (props.hideOnScroll ? "none" : "0 1px 2px 0 rgba(0, 0, 0, 0.05)")};

  @media (max-width: 768px) {
    position: sticky;
    padding: 0 1rem;
    height: 8vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
    ${tw`bg-cygrey`};
  }
`
const Toggle = styled.div`
  ${tw`flex`}
  ${tw`md:hidden`}
  height: 100%;
  cursor: pointer;
  padding: 0 .2rem;
`
const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    ${tw`bg-cygrey`};
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${props => (props.open ? "-100%" : "0")};
  }

`
const Hamburger = styled.div`
  ${tw`bg-cyblue`};
  width: 30px;
  height: 3px;
  transition: all .3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    ${tw`bg-cyblue`};
    content: "";
    position: absolute;
    transition: all 0.3s linear;


  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`


const Navbar = ({logoImage}) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [navboxOpen, setNavboxOpen] = useState(false)
  const [hideOnScroll, setHideOnScroll] = useState(true)

  useScrollPosition(({prevPos, currPos}) => {
    const isShow = currPos.y > -650
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  },
    [hideOnScroll])

  return (
    <Navigation hideOnScroll = {hideOnScroll} navbarOpen = {navbarOpen} >
      <Logo logoImage={logoImage} />
      <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
          <Navbox>
            <NavbarLinks />
          </Navbox>
        ) : (
          <Navbox open>
            <NavbarLinks />
          </Navbox>
        )
      }
    </Navigation>
  )
}

export default Navbar
