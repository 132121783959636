import React from "react"
import tw, {styled} from 'twin.macro'
import { Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

const LogoWrap = tw.div`
  my-auto
 mx-0
 p-1
 md:py-1
 md:px-0
 flex-shrink
 object-contain
`
const ImageBox = styled.div`
  margin: auto 0;
  width: 11rem;
  height: 1.8rem;
`

const Logo = ({logoImage}) => {
  return(
    <LogoWrap >
      <Link tw="no-underline" to="/">
        <BackgroundImage fluid={logoImage.fluid} 
         alt="Culture Yard Logo"
        ><ImageBox></ImageBox></BackgroundImage>
      </Link>
    </LogoWrap>
  )
}

export default Logo



